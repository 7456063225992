import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  ADMINORGANIZATION,
} from '../../../../constants/applicationConstants';
import {
  DataTableHoc,
  Loader,
  ErrorFallback,
  deleteFilter,
  applyFilter,
  saveFilters,
} from 'smart-react';
import { GridColumnsList } from '../Components/Filter/FilterElements';
import DataColumns from '../Components/DataColumns/DataColumns';
import { useAuth } from '../../../Core/Context/AuthContext';
import { isAdminRole } from '../../../../Utils/Auth/ValidatePermissions';
import NoPermission from '../../../Core/NoPermission/NoPermission';
import {
  ROW_LAYOUTS,
  PAGE_SIZE,
} from '../../../../constants/applicationConstants';
const BaseAdminDataTable = React.lazy(
  () => import('../Components/DataTable/DataTable')
);
const AdminOrganizationDataTable = DataTableHoc(BaseAdminDataTable);

/**
 * AdminOrganization main screen
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The AdminOrganization component.
 */
const AdminOrganization = () => {
  const [userData, setUserData] = React.useState([]);
  const dataColumnsArray = DataColumns({});
  // used to check API is ready to call
  const [isReady, setIsReady] = React.useState(false);
  const { user } = useAuth();

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className='container mx-auto'>
        {!isAdminRole(user) && <NoPermission />}
        {isAdminRole(user) && (
          <React.Suspense fallback={<Loader />}>
            <AdminOrganizationDataTable
              deleteFilter={deleteFilter}
              applyFilter={applyFilter}
              saveFilters={saveFilters}
              moduleName={ADMINORGANIZATION}
              dataColumns={dataColumnsArray}
              gridColumnsList={GridColumnsList}
              dataTableName={ADMINORGANIZATION}
              setIsReady={setIsReady}
              isReady={isReady}
              rowLayoutConfigName={`${ADMINORGANIZATION}.DataGrid.RowLayout`}
              pageLengthConfigName={`${ADMINORGANIZATION}.DataGrid.ItemsPerPage`}
              dataSet={userData}
              setDataSet={setUserData}
              initialSort={{
                field: 'id',
                dir: 'desc',
              }}
              isCardonMobileView={false}
              total={process.env.DefaultLimit}
              defaultRowLayout={process.env.RowLayout}
              rowLayouts={ROW_LAYOUTS}
              pageSizes={PAGE_SIZE}
              isDefaultSlider={true}
              contentSliderTitle={'Organization'}
            />
          </React.Suspense>
        )}
      </div>
    </ErrorBoundary>
  );
};
export default AdminOrganization;
