import { apiCall } from '../../../../Middleware/API/apiRequest';
import { getTokensFromStorage } from '../../../../Utils/Auth/AuthToken';
import { ADMIN_ORGANIZATION } from '../../../../constants/apiRoutes';
import {
  getGraphqlCacheResponse,
  setGraphqlCacheResponse,
} from '../../../../Utils/Storage/IndexDB/indexDbWrappers';
/**
 * list Organizations.
 * @returns {Promise} - A promise that resolves with the list of Organizations or rejects with an error.
 * @param props
 */
export const listOrganization = async ({
  accessToken,
  limit,
  offset,
  filter,
  sort,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const data = {
    Filters: filter,
    pageNumber: offset === 0 ? 1 : offset,
    pageSize: limit,
    sort,
  };

  const response = await apiRequest({
    url: `${ADMIN_ORGANIZATION}/Filter`,
    method: 'POST',
    body: data,
    moduleName,
    isServiceWorker,
    listController,
    accessToken,
  });
  if (response?.isError) {
    if (isServiceWorker) {
      let cacheResponse = await getGraphqlCacheResponse(
        headers,
        isServiceWorker
      );
      return cacheResponse;
    }
    return response;
  }
  if (isServiceWorker) {
    await setGraphqlCacheResponse(headers, response, isServiceWorker);
  }
  return {
    Organizations: response.Payload,
    TotalCount: response?.TotalRecordCount,
  };
};

/**
 * Create Organization.
 * @returns {Promise} - A promise that resolves with the creation of Profile Group or rejects with an error.
 * @param data
 */
export const createOrganization = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${ADMIN_ORGANIZATION}`,
    method: 'POST',
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return response;
};

/**
 * Update Organization.
 * @returns {Promise} - A promise that resolves with the update of Profile Group or rejects with an error.
 * @param data
 */
export const updateOrganization = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${ADMIN_ORGANIZATION}`,
    method: 'PUT',
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return response;
};

/**
 * delete Organization.
 * @returns {Promise} - A promise that resolves with the update of Profile Group or rejects with an error.
 * @param data
 */
export const deleteOrganization = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${ADMIN_ORGANIZATION}/${data?.organization_id}`,
    method: 'DELETE',
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return response;
};

/**
 * get Organization.
 * @returns {Promise} - A promise that resolves with the Organization or rejects with an error.
 * @param data
 */
export const getOrganization = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${ADMIN_ORGANIZATION}/${data?.organization_id}`,
    method: 'GET',
    moduleName,
    isServiceWorker,
    listController,
    accessToken,
  });
  return response;
};

/**
 * UserDetail Api Call Wrapper Method.
 * @param {object} api - Contains details like url headers method and data.
 * @returns {Promise} - A promise that resolves with the data of user or rejects with an error.
 */
const apiRequest = async (request) => {
  let response;
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  request.headers = headers;
  try {
    response = await apiCall(request);
  } catch (e) {
    response = { isSuccess: false, message: e.message };
  }
  return response;
};
