import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Loader, ErrorFallback, TextInput, InputSwitch } from 'smart-react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardHeader, CardBody } from '@progress/kendo-react-layout';
import DesktopLayout from '../Components/Layout/DesktopLayout';
import { Button } from '@progress/kendo-react-buttons';
import {
  buildNotification,
  GenerateNotification,
  isValidate,
  isFromValid,
  DescriptionTextArea,
} from 'smart-react';
import {
  createOrganization,
  updateOrganization,
} from '../Services/OrganizationService';
import { isAdminRole } from '../../../../Utils/Auth/ValidatePermissions';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Field } from '@progress/kendo-react-form';
import {
  EVENTS_DATA_TYPES,
  NOTIFICATION_TYPES,
} from '../../../../constants/eventDataTypes';
import { Error } from '@progress/kendo-react-labels';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../../Core/Context/AuthContext';
import NoPermission from '../../../Core/NoPermission/NoPermission';
import { getOrganization } from '../Services/OrganizationService';
import { SplitButton, SplitButtonItem } from '@progress/kendo-react-buttons';
import {
  CREATE_MESSAGE,
  UPDATE_MESSAGE,
  ERROR_MESSAGE,
} from '../../../../constants/notificationMessages';
/**
 * Add New RF Profile main screen
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Add New RF Profile component.
 */
const AddOrganization = () => {
  const navigate = useNavigate();
  const { user } = useAuth(); // Get tenantID using Auth Context
  /**
   * Used to handle multiple tabs
   */
  const [detailsDataModified, setDetailsDataModified] = React.useState(false);
  const [isTextArea, setIsTextArea] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [selected, setSelected] = React.useState(0);
  const [shouldUpdate, setShouldUpdate] = React.useState(false);
  const [formData, setFormData] = React.useState({
    Id: '',
    name: '',
    obj_prefix: '',
    email_domain: '',
    description: '',
    active: true,
  });
  const [validateForm, setValidateForm] = React.useState(false);
  const requiredFields = ['name', 'obj_prefix', 'email_domain', 'description'];
  const [editorField, setEditorField] = React.useState({
    name: 'description',
    value: formData?.description,
    label: 'Description',
  });
  const { state } = useLocation();

  const [tempState, setTempState] = React.useState(state);

  /**
   * Get Profile Group Detail from API for edit Profile Group
   */
  React.useEffect(() => {
    if (state) {
      const { dataSet } = state;
      (async () => {
        setLoading(true);
        const response = await getOrganization({
          data: dataSet,
        });
        const testData = response?.Payload;
        setFormData(testData);
        setLoading(false);
      })();
      setTempState({ ...state });
      setFormData({ ...dataSet });
      setDetailsDataModified(true);
    } else {
      setDetailsDataModified(true);
    }
  }, [state]);

  /**
   * handle Input Change
   * @e object
   */
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? (checked ? 1 : 0) : value;
    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  /**
   * Handle Selected Tab
   * @param {*} e
   */
  const handleSelect = (e) => {
    setSelected(e.selected);
    setShouldUpdate(!shouldUpdate);
  };

  /**
   * onSave
   * submit the organization data to api
   */
  const onSave = async ({ isExit = false, isContinue = false }) => {
    setValidateForm(true);
    if (!isFromValid({ formData, requiredFields })) {
      return;
    }

    setLoading(true);
    let notificationMetaData = {};
    const data = {
      organization_id: formData?.organization_id,
      TenantId: '',
      organizationVM: {
        organization_id: formData?.organization_id,
        name: formData?.name,
        obj_prefix: formData?.obj_prefix,
        email_domain: formData?.email_domain,
        description: formData?.description,
        active: formData?.active,
      },
      tenantVM: {
        Id: '',
        Name: formData?.name,
        Active: true,
        IsOrganization: true,
      },
    };
    let response;
    let method;

    if (state?.edit) {
      method = updateOrganization;
      notificationMetaData = UPDATE_MESSAGE;
    } else {
      method = createOrganization;
      notificationMetaData = CREATE_MESSAGE;
      setFormData({
        name: '',
        obj_prefix: '',
        email_domain: '',
        description: '',
        active: true,
      });
    }

    try {
      setValidateForm(false);
      response = await method({
        data,
        isServiceWorker: false,
      });

      if (response.Message) {
        GenerateNotification(
          buildNotification({
            title: ERROR_MESSAGE?.title,
            description: response.Message,
            style: ERROR_MESSAGE?.style,
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
        );
      } else if (!response?.IsSuccess) {
        GenerateNotification(
          buildNotification({
            title: ERROR_MESSAGE?.title,
            description: response.StatusDesc,
            style: ERROR_MESSAGE?.style,
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
        );
      } else {
        GenerateNotification(
          buildNotification(notificationMetaData),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
        );
        const responseOrg = await getOrganization({
          data: response?.Payload?.organizationVM,
        });
        const testData = responseOrg?.Payload;
        // Update tempState to trigger rerender
        setTempState({
          ...state,
          dataSet: testData,
        });
        if (isContinue) {
          navigate(`/admin/organization`, {
            state: {
              edit: true,
              dataSet: testData,
            },
          });
        }
        if (isExit) {
          navigate('/admin/organizations');
        }
      }
    } catch (error) {
      GenerateNotification(
        buildNotification(ERROR_MESSAGE),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
      );
    } finally {
      setLoading(false);
    }
  };

  /**
   * onExit
   * back to organizations listing screen
   */
  const onExit = () => {
    navigate('/admin/organizations');
  };

  const handleSaveSplit = (e) => {
    const { itemIndex } = e;
    switch (itemIndex) {
      case 0: {
        onSave({ isExit: true });
        break;
      }
      case 1:
        onSave({ isContinue: true });
      default: {
      }
    }
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className='container mx-auto'>
        {!isAdminRole(user) && <NoPermission />}
        {isAdminRole(user) && (
          <React.Suspense fallback={<Loader />}>
            {loading && <Loader />}

            <>
              <div className={`${isTextArea ? '' : 'k-d-none'}`}>
                <DescriptionTextArea
                  onChange={handleInputChange}
                  label={'Description'}
                  renderTypes={!detailsDataModified ? 'ReadOnly' : ''}
                  validateForm={validateForm}
                  formData={formData}
                  setFormData={setFormData}
                  detailsDataModified={detailsDataModified}
                  isTextArea={isTextArea}
                  setIsTextArea={setIsTextArea}
                  editorField={editorField}
                  setEditorField={setEditorField}
                  row={2}
                />
              </div>
              <div
                className={`add-rf-profile-group main-page-section add-page-section ${!isTextArea ? '' : 'k-d-none'}`}
              >
                <Card className='tophead'>
                  <CardHeader>
                    <div className='action-buttons-container'>
                      <Link
                        className='action-icon-wrapper !k-d-block'
                        to={`/admin/organizations`}
                      >
                        <FontAwesomeIcon
                          className='k-mr-2 k-pt-3'
                          icon={faArrowLeft}
                        />
                      </Link>
                      <div>
                        <p className='display-text k-mb-0'>
                          Organization: {formData?.name}
                        </p>
                      </div>
                    </div>
                    {formData?.description && (
                      <div className='desciption-label'>
                        <span className='k-d-block k-mb-2'>
                          {formData?.description}
                        </span>
                      </div>
                    )}
                  </CardHeader>
                </Card>
                <Card className='k-mt-3 detail-card'>
                  <CardHeader>
                    <h6 className='card-title-secondary'>Details</h6>
                  </CardHeader>
                  <CardBody className='detail-card-body'>
                    <div>
                      <Form
                        onSubmit={onSave}
                        render={(formRenderProps) => (
                          <div className='card-content'>
                            <div className='k-d-flex single-field-row'>
                              <div
                                className={`field-wrapper ${isValidate(validateForm, formData?.name) ? 'field-invalid' : 'field-valid'}`}
                              >
                                <Field
                                  key={'name'}
                                  id={'name'}
                                  name={'name'}
                                  data={formData?.name}
                                  onChange={handleInputChange}
                                  label={'Name'}
                                  type={'text'}
                                  component={TextInput}
                                  renderTypes={
                                    !detailsDataModified ? 'ReadOnly' : ''
                                  }
                                  optional={false}
                                  requiredField={true}
                                />
                                {isValidate(validateForm, formData?.name) && (
                                  <Error>This field cannot be empty</Error>
                                )}
                              </div>
                            </div>
                            <div className='k-d-flex single-field-row'>
                              <div
                                className={`field-wrapper ${isValidate(validateForm, formData?.obj_prefix) ? 'field-invalid' : 'field-valid'}`}
                              >
                                <Field
                                  key={'obj_prefix'}
                                  id={'obj_prefix'}
                                  name={'obj_prefix'}
                                  data={formData?.obj_prefix}
                                  onChange={handleInputChange}
                                  label={'Prefix'}
                                  type={'text'}
                                  component={TextInput}
                                  renderTypes={
                                    !detailsDataModified ? 'ReadOnly' : ''
                                  }
                                  optional={false}
                                  requiredField={true}
                                />
                                {isValidate(
                                  validateForm,
                                  formData?.obj_prefix,
                                ) && <Error>This field cannot be empty</Error>}
                              </div>
                              <div
                                className={`field-wrapper ${isValidate(validateForm, formData?.email_domain) ? 'field-invalid' : 'field-valid'}`}
                              >
                                <Field
                                  key={'email_domain'}
                                  id={'email_domain'}
                                  name={'email_domain'}
                                  data={formData?.email_domain}
                                  onChange={handleInputChange}
                                  label={'Email Domains'}
                                  type={'text'}
                                  component={TextInput}
                                  renderTypes={
                                    !detailsDataModified ? 'ReadOnly' : ''
                                  }
                                  optional={false}
                                  requiredField={true}
                                />
                                {isValidate(
                                  validateForm,
                                  formData?.email_domain,
                                ) && <Error>This field cannot be empty</Error>}
                              </div>
                            </div>
                            <div className='k-d-flex single-field-row'>
                              <div
                                className={`field-wrapper field-wrapper-description field-wrapper-100 ${isValidate(validateForm, formData?.description) ? 'field-invalid' : 'field-valid'}`}
                              >
                                <DescriptionTextArea
                                  onChange={handleInputChange}
                                  label={'Description'}
                                  renderTypes={
                                    !detailsDataModified ? 'ReadOnly' : ''
                                  }
                                  validateForm={validateForm}
                                  formData={formData}
                                  setFormData={setFormData}
                                  detailsDataModified={detailsDataModified}
                                  isTextArea={isTextArea}
                                  setIsTextArea={setIsTextArea}
                                  editorField={{
                                    name: 'description',
                                    value: formData?.description,
                                    label: 'Description',
                                  }}
                                  requiredField={true}
                                  setEditorField={setEditorField}
                                  row={2}
                                />
                                {isValidate(
                                  validateForm,
                                  formData?.description,
                                ) && <Error>This field cannot be empty</Error>}
                              </div>
                            </div>
                            <div className='single-field-row k-mt-4 single-row-60'>
                              <div className={`field-wrapper`}>
                                <Field
                                  name='active'
                                  component={InputSwitch}
                                  label='Active'
                                  onLabel='Yes'
                                  offLabel='No'
                                  id='active'
                                  checked={formData?.active}
                                  onChange={handleInputChange}
                                  disabled={!detailsDataModified}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      />
                    </div>
                  </CardBody>
                </Card>
                {detailsDataModified && (
                  <Card className='k-mt-3'>
                    <CardHeader>
                      <div className='k-d-flex single-field-row button-form-wrapper k-action-buttons !k-px-0'>
                        <>
                          <Button
                            icon={'cancel'}
                            onClick={() => onExit()}
                            type='button'
                          >
                            Cancel
                          </Button>
                          <SplitButton
                            text='save'
                            themeColor={'primary'}
                            onButtonClick={() =>
                              onSave({ isExit: false, isContinue: true })
                            }
                            onItemClick={handleSaveSplit}
                          >
                            <SplitButtonItem text='Save & Close' />
                          </SplitButton>
                        </>
                      </div>
                    </CardHeader>
                  </Card>
                )}
                {tempState?.edit && (
                  <Card className='k-mt-3 detail-grid-section'>
                    <CardBody className='!k-px-0'>
                      <DesktopLayout
                        handleSelect={handleSelect}
                        selected={selected}
                        detailsDataModified={detailsDataModified}
                        state={tempState}
                        shouldUpdate={shouldUpdate}
                        setShouldUpdate={setShouldUpdate}
                      />
                    </CardBody>
                  </Card>
                )}
              </div>
            </>
          </React.Suspense>
        )}
      </div>
    </ErrorBoundary>
  );
};
export default AddOrganization;
