import * as React from 'react';
import {
  Card,
  CardBody,
  TabStrip,
  TabStripTab,
} from '@progress/kendo-react-layout';
import OrganizationUser from '../../../../Core/OrganizationUsers/Pages/OrganizationUser';
import ProductList from '../../../../Core/ProductList/ProductList';
import { ADMINORGANIZATIONUSERS } from '../../../../../constants/applicationConstants';
import { isComponentUpdate } from 'smart-react';

/**
 * Test Details Desktop view
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The DesktopView component.
 */
const DesktopView = ({
  selected,
  handleSelect,
  detailsDataModified,
  state,
}) => {
  const [organization, setOrganization] = React.useState(state.dataSet);
  React.useEffect(() => {
    setOrganization(state.dataSet);
  }, [state]);
  return (
    <>
      <Card>
        <TabStrip
          className='page-tabs'
          selected={selected}
          onSelect={handleSelect}
        >
          <TabStripTab
            title='User'
            contentClassName={`tabs-card ${
              detailsDataModified === true ? 'tab-modified' : ''
            }`}
          >
            <Card
              className={`tabs-card ${
                detailsDataModified === true ? 'tab-modified' : ''
              }`}
            >
              <CardBody>
                <div className='card-body'>
                  <OrganizationUser orgId={organization?.organization_id} moduleName={ADMINORGANIZATIONUSERS} />
                </div>
              </CardBody>
            </Card>
          </TabStripTab>
          <TabStripTab
            title='Products'
            contentClassName={`tabs-card ${
              detailsDataModified === true ? 'tab-modified' : ''
            }`}
          >
            <Card
              className={`tabs-card ${
                detailsDataModified === true ? 'tab-modified' : ''
              }`}
            >
              <CardBody>
                <div className='card-body'>
                  <div className='k-py-5'>
                    <ProductList
                      isOrganizationProduct={true}
                      tenantID={organization?.tenant_id}
                      subscribed={false}
                      isAdminProduct={true}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </TabStripTab>
        </TabStrip>
      </Card>
    </>
  );
};

// Export the memoized component
export default React.memo(DesktopView, isComponentUpdate);
